<template>
  <b-modal id="sign-up-modal"
           body-bg-variant="coool"
           header-bg-variant="coool"
           header-class="header-class"
           @hidden="resetModal()"
           centered
           hide-footer
           size="lg"
           no-close-on-backdrop
           body-class="modal-class">
    <div class="close-btn" @click="$bvModal.hide('sign-up-modal')">
      <img src="../assets/icn_close.svg" class="cross" alt="X">
    </div>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="sent_success" class="sent-pad">
      <img src="../assets/img_thank_you.png" class="d-block mx-auto thank" alt="">
      <div class="d-block text-center">
        <p class="title">Thank you!</p>
        <div class="descr ">
          <p class="mb-0">Thank you for reaching out about RPIE compliance. </p>
          <p class="mb-0">We will be in touch with you shortly.</p>
        </div>
      </div>
      <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Done!</button>
    </div>
    <div v-else-if="sent_error" class="sent-pad">
<!--      <img src="../assets/error_outline.png" class="d-block mx-auto" alt="">-->
      <div class="d-block text-center p-3">
        <p class="title">Something went wrong</p>
        <div class="descr form-sent">
          <p class="mb-0">We are working on this problem now. </p>
          <p class="mb-0">Please try again or contact us: <b>718-522-1111</b></p>
        </div>
        <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Try again</button>
      </div>
    </div>
    <div v-else>
      <div class="d-block text-center">
        <p class="title text-center">Get Help Completing RPIE</p>
      </div>
      <div class="wrap-inputs">
        <div class="row radios-block">
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="name"
                class="cust-inp"
                :class="name_state === false ? 'err-state' : ''"
                v-model="name"
                :state="name_state"
                placeholder="First name"
                trim
                type="text"
            ></b-form-input>
            <div v-if="name_state  === false" >
              <p v-if="!name || name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                First name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="lname"
                :class="last_name_state === false ? 'err-state' : ''"
                class="cust-inp"
                v-model="last_name"
                :state="last_name_state"
                placeholder="Last name"
                trim
                type="text"
            ></b-form-input>
            <div v-if="last_name_state  === false" >
              <p v-if="!last_name || last_name.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Last name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input
                id="mail"
                :class="mail_state === false ? 'err-state' : ''"
                class="cust-inp"
                v-model="mail"
                :state="mail_state"
                placeholder="Email"
                type="email"
                trim
            ></b-form-input>
            <div v-if="mail_state  === false">
              <p v-if="!mail || mail.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Email is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <TheMask  class="cust-inp form-control"
                      v-model="phone"
                      :class="phone_state ===  false ? 'err-state' : ''"
                      placeholder="Phone Number"
                      mask="(###) ###-####">
            </TheMask>
            <div v-if="phone_state  === false">
              <p v-if="!phone || phone.length === 0"  class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Phone number is incorrect
              </p>
            </div>
          </div>
<!--          <div class="col-12 mb-32px">-->
<!--            <b-form-input-->
<!--                id="mail"-->
<!--                class="cust-inp"-->
<!--                :class="address_state ===  false ? 'err-state' : ''"-->
<!--                v-model="address"-->
<!--                :state="address_state"-->
<!--                placeholder="Property Address"-->
<!--                type="text"-->
<!--                trim-->
<!--            ></b-form-input>-->
<!--            <div v-if="address_state  === false">-->
<!--              <p v-if="!address || address.length === 0"  class="err-state-text m-0">-->
<!--                Required-->
<!--              </p>-->
<!--              <p v-else class="err-state-text m-0">-->
<!--                Address is incorrect-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
          <div class="col-12" >
            <div v-if="sent_success || sent_error" class="lic-less d-flex justify-content-center hand">
              <button @click="$bvModal.hide('sign-up-modal')"  class="help-btn  w-100" >Close</button>
            </div>
            <div v-else class="lic-less d-flex justify-content-center">
              <button @click="signUp" :class="disabled_send ? 'not-allowed' : 'hand'" class="help-btn  w-100" >Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Preloader from '../components/Preloader.vue'
import axios from 'axios'
import sbjs from 'sourcebuster';
import Vue from "vue";

Vue.use(sbjs)
sbjs.init();
export default {
name: "SignUpModal",
  components: {
    TheMask,
    Preloader
  },

  data: () => ({
    name_state: null,
    last_name_state: null,
    mail_state: null,
    phone_state: null,
    income_state: null,
    address_state: null,
    name: null,
    last_name: null,
    mail: null,
    phone: null,
    address: null,
    sent_success : false,
    sent_error : false,
    is_loading : false,
    disabled_send : false,
  }),
  watch:{
    name(){
      this.name_state = null
    },
    last_name(){
      this.last_name_state = null
    },
    mail(){
      this.mail_state = null
    },
    phone(){
      this.phone_state = null
    },
    address(){
      this.address_state = null
    },
  },
  methods:{
    resetModal(){
      this.sent_success = false;
      this.is_loading = false;
      this.sent_error = false;
      this.name = null;
      this.last_name = null;
      this.mail = null;
      this.phone = null;
      this.address = null;
      this.name_state = null;
      this.last_name_state =  null;
      this.mail_state = null;
      this.phone_state = null;
      this.address_state = null;

    },
    async signUp(){
      if(this.validate() && !this.disabled_send){
        this.makeDisabled()
        this.is_loading = true;
        await axios.post('https://mgny.force.com/sales/services/apexrest/lead/add',
            {
              'FirstName': this.name,
              'LastName': this.last_name,
              'Email': this.mail,
              'Phone': this.phone,
              'Address' : this.address,
              'FirstSource' : sbjs.get.first.src + '-' +  sbjs.get.first.mdm + '-' + sbjs.get.first.cmp + '-' + sbjs.get.first.trm,
              'LastSource' : sbjs.get.current.src + '-' +  sbjs.get.current.mdm + '-' + sbjs.get.current.cmp + '-' + sbjs.get.current.trm,

            })
            .then(()=>{
              this.sent_success = true
              this.is_loading = false;
            }).catch((error)=>{
              this.sent_error = true;
              this.is_loading = false;
              console.log('Error' + error)
            })
      }
    },
    makeDisabled(){
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 10000)
    },
    validate(){
      this.name_state = null;
      this.last_name_state = null;
      this.mail_state = null;
      this.phone_state = null;
      this.address_state = null
      let is_valid = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if(this.name === null || this.name.length < 2){
        this.name_state = false;
        is_valid = false
      }
      if(this.last_name === null || this.last_name.length < 2){
        this.last_name_state = false;
        is_valid = false
      }
      if(this.mail === null || this.mail.length < 5 || !(re.test(String(this.mail).toLowerCase())) ){
        this.mail_state = false;
        is_valid = false
      }
      // if(this.phone && this.phone.length !== 10){
      if(this.phone === null || this.phone.length !== 10){
        this.phone_state = false;
        is_valid = false
      }
      // if(this.address === null || this.address.length < 2){
      //   this.address_state = false;
      //   is_valid = false
      // }

    return is_valid

    }
  }
}
</script>
<style>

.wrap-inputs{
  padding: 0 92px 56px;
  width: 100% !important;
  margin-top: 40px;
}
.not-allowed{
  cursor: not-allowed;
}
.modal-header {
  border-bottom: none!important;
}
.bg-coool{
  background: #FFFFFF;
}
.modal-content {
  border-radius: 10px 10px 10px 10px!important;
}
.modal-class{
  border-radius: 10px!important;
}
.header-class{
  border-radius: 10px!important;
}
.modal-footer > * {
  margin: 0!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #FF2929;
}
.form-control {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}
.modal-backdrop{
  transition: 0.8s;
  opacity: 0.88!important;
  background-color: rgba(1,2,15,1)!important
}
#sign-up-modal___BV_modal_header_{
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .close-btn{
    display: none;
  }
  #sign-up-modal___BV_modal_header_{
    display: block;
  }
  .modal-dialog {
    max-width: 640px!important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  close-btn{
    display: none;
  }
  #sign-up-modal___BV_modal_header_{
    display: block;
  }
}
</style>


<style lang="scss" scoped>
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 8px 13px;
}
.cross{
  height: 15px;
  width: 15px;
  color: #FFFFFF;
}
.sent-pad{
  padding: 6px 72px 40px;
}
.err-state-text{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
}
.err-state{
  background-color: #FFE9E8!important;
  border: 1px solid #FF7564!important;
  &::placeholder{
    color: #FF7564!important;
    font-family: "Open Sans";
  }
}
.err-state.long{
  width: 350px;
  right: 0px;
}
.err-state::after{
  content: '';
  position: absolute;
  left: 49%;
  top: 100%;
  border-left: 4px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f60606;
}
::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #C6C6C6;
}
.mb-32px{
  margin-bottom: 32px;
}
.mb-40px{
  margin-bottom: 40px;
}
.thank{
  width: 109.6px;
  margin-bottom: 50px;
}
.title{
  margin : 8px 0 12px;
  height: 49px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 36px;
  letter-spacing: -0.63px;
  line-height: 49px;
  text-align: center;
}
.descr{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 87px;
}
.text-blue{
  color: #2692F5;
}
.description-wrap{
  padding:30px
}
.cust-inp{
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #F5F5F7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0A1250;
  &::placeholder{
    color: #7F83A7;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
   }
  &:active{
    box-sizing: border-box;
    height: 48px;
    border: 1px solid #4D59EB;
    border-radius: 6px;
  }
  &:focus{
    box-shadow: none;
    border: 1px solid #4D59EB;
  }
}
.hand{
  cursor: pointer;
}

@media screen and (min-width: 768px) and (max-width: 1223px) {
  .wrap-inputs{
    padding: 0 32px 32px;
    width: 100% !important;
    margin-top: 40px;
  }
  .title {
    margin: -19px 0 12px;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .title{
    margin : -30px 0 2px;
    font-size: 24px;
    line-height: 40px;
  }
  .wrap-inputs{
    padding: 0 16px 32px;
    width: 100% !important;
    margin-top: 25px;
  }
  .mb-32px{
    margin-bottom: 28px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .title{
    margin : -30px 0 -5px;
    font-size: 22px;
    line-height: 37px;
  }
  .wrap-inputs{
    padding: 0 1px 32px;
    width: 100% !important;
    margin-top: 15px;
  }
  .mb-32px{
    margin-bottom: 24px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 50px;
  }
}

</style>